.k-button {
  --btn-font-weight: 600;
  --btn-font-size: 15px;
  --btn-font-color: var(--color-black-subtle);
  --btn-line-height: 1.3;
  --btn-padding-inline: 12px;
  --btn-padding-block: 16px;
  --btn-border-radius: 6px;
  --btn-gap: 8px;

  font-weight: var(--btn-font-weight);
  font-size: var(--btn-font-size);
  color: var(--btn-font-color);
  line-height: var(--btn-line-height);
  gap: var(--btn-gap);
  border-radius: var(--btn-border-radius);
  box-shadow: none !important;
  padding-block: var(--btn-padding-inline);
  padding-inline: var(--btn-padding-block);
  transition-duration: 0.4s;
  transition-property: color, background-color, border-color;

  svg {
    * {
      stroke: currentColor;
      transition: all 0.4s;
    }
  }
}

.k-button-text {
  &:has(.btn-icon) {
    display: flex;
    align-items: center;
    gap: var(--btn-gap);
  }
}

.k-button-solid-primary,
.k-button-solid-secondary {
  --btn-font-color: var(--color-white);
}

.k-button-flat,
.k-button-flat-base {
  --btn-font-color: var(--color-black-subtle);
}

.k-button-flat-primary,
.k-button-outline-primary {
  --btn-font-color: var(--color-primary);
}

.k-button-link-primary {
  --btn-font-color: #0044CC;

  &:hover {
    color: var(--color-link-primary-hover);
  }
}

.k-button-flat {
  --button-flat-hover-bg: #F4F5F6;

  &::before {
    visibility: hidden;
  }

  &:hover {
    background: var(--button-flat-hover-bg) !important;
  }
}

.k-button-outline-base {
  border-color: var(--color-border-primary);

  &:hover,
  &.k-hover {
    background-color: #F4F5F6;
    color: var(--color-black-subtle);
    border-color: var(--color-border-primary);
  }
}

.kendo-icon-btn {
  --btn-font-color: var(--color-subtle-dark);
  background-color: #F4F5F6;

  &:hover {
    border-color: var(--color-border-primary);
    background-color: var(--color-white);
  }
}

.kendo-circle-btn {
  border-radius: 50%;
}

.filter-panel-close-btn {
  min-width: 40px;
  aspect-ratio: 1;
}

.k-button-sm {
  --btn-font-size: 15px;
  --btn-padding-inline: 12px;
  --btn-padding-block: 16px;

  &.kendo-icon-btn {
    --btn-padding-inline: 8px;
    --btn-padding-block: 8px;
  }

  &.share-icon-btn {
    --btn-padding-inline: 5px;
    --btn-padding-block: 5px;

    img {
      max-width: 16px;
      max-height: 16px;
    }
  }
}

.k-button-md {
  --btn-font-size: 18px;
  --btn-padding-inline: 12px;
  --btn-padding-block: 20px;

  &.kendo-icon-btn {
    --btn-padding-inline: 12px;
    --btn-padding-block: 12px;
  }
}

.k-button-lg {
  --btn-font-size: 20px;
  --btn-padding-block: 40px;
  --btn-padding-inline: 24px;

  @include mq(phone) {
    --btn-padding-block: 24px;
    --btn-padding-inline: 20px;
  }
}

.btn-xs {
  --btn-font-size: 13px;
  --btn-padding-inline: 6px;
  --btn-padding-block: 12px;
}

.k-button-link {
  --btn-padding-inline: 0;
  --btn-padding-block: 0;
  align-self: flex-start;
  text-decoration: underline !important;
  border-radius: 0;
}

.btn-light {
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }
}

.share-icon-btn-on-img {
  background-color: #EFF8FF;
  border-color: #B2DDFF;
}

.like-btn {
  --btn-font-size: 14px;
  --btn-padding-inline: 2px;
  --btn-padding-block: 0;
  --btn-border-radius: 2px;
  --button-flat-hover-bg: transparent;

  &::after {
    visibility: hidden;
  }

  svg * {
    fill: transparent;
  }

  &.liked {
    svg * {
      stroke: #B6591A;
      fill: #ED7D31;
    }
  }
}

.btn-cta {
  --btn-font-size: 16px;
  --btn-padding-inline: 10px;
  --btn-padding-block: 16px;
  --btn-border-radius: 38px;
  background-color: #FDFBFB;
  border-color: #ECE2DF;

  &:hover,
  &.is-active {
    background-color: #FDF0E8;
    border-color: #ED7D31;
  }
}
