/* Fix for Kendo UI line-height variable error */
.k-button {
  line-height: normal !important;
  /* Override the problematic variable */
}

/* If more specific selectors are needed */
.k-button-solid,
.k-button-outline,
.k-button-flat,
.k-button-text {
  line-height: normal !important;
}

/* Additional potential fixes if the issue persists in other components */
[class^="k-"] {
  --kendo-line-height: 1.4285714286;
}

/* Fix for Kendo UI line-height variable errors */
.k-button,
.k-button-solid,
.k-button-outline,
.k-button-flat,
.k-button-text {
  line-height: normal !important;
}

/* Fix for box-shadow error */
.k-button-sol {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3) !important;
}

/* Fix for border-color variable error */
.resource-car {
  border-color: transparent !important;
}

/* Global CSS variable fixes */
:root {
  --kendo-line-height: normal;
  --resource-card-icon-color: currentColor;
}
