@import '@progress/kendo-theme-default/scss/button';
@import '@progress/kendo-theme-default/scss/menu';
@import '@progress/kendo-theme-default/scss/input';
@import '@progress/kendo-theme-default/scss/tabstrip';
@import '@progress/kendo-theme-default/scss/panelbar';
@import '@progress/kendo-theme-default/scss/expansion-panel';
@import '@progress/kendo-theme-default/scss/popover';
@import '@progress/kendo-theme-default/scss/dialog';
@import '@progress/kendo-theme-default/scss/upload';
@import '@progress/kendo-theme-default/scss/editor';
@import '@progress/kendo-theme-default/scss/dateinput';
@import '@progress/kendo-theme-default/scss/datepicker';
@import '@progress/kendo-theme-default/scss/daterangepicker';
@import '@progress/kendo-theme-default/scss/datetimepicker';
@import '@progress/kendo-theme-default/scss/scrollview';
@import '@progress/kendo-theme-default/scss/progressbar';
@import '@progress/kendo-theme-default/scss/notification';
@import '@progress/kendo-theme-default/scss/grid';
@import '@progress/kendo-theme-default/scss/chip';
@import "@progress/kendo-theme-default/dist/all.css";
