:root {
  --evg-font-family-primary: "Figtree", serif;
}

.title-xs {
  color: var(--color-black-subtle);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;

  @include mq(phone) {
    font-size: 14px;
  }
}

.title-sm {
  color: var(--color-black-subtle);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;

  @include mq(phone) {
    font-size: 18px;
  }
}

.title-md {
  color: var(--color-black-subtle);
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;

  @include mq(phone) {
    font-size: 23px;
  }
}

.title-lg {
  color: var(--color-black-subtle);
  font-size: 42px;
  font-weight: 700;
  line-height: 1.1;

  @include mq(phone) {
    font-size: 32px;
  }
}

.title-xl {
  color: var(--color-black-subtle);
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;

  @include mq(phone) {
    font-size: 42px;
  }
}

.body-sm {
  color: var(--color-black-subtle);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.body-md {
  color: var(--color-black-subtle);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}


// Font Weights
.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

// Line Heights
.line-height-12 {
  line-height: 1.2
}

.line-height-13 {
  line-height: 1.3
}

.line-height-14 {
  line-height: 1.4
}

.line-height-15 {
  line-height: 1.5
}

.line-height-16 {
  line-height: 1.6
}

// colors
.font-color-light {
  color: var(--color-white);
}

.font-color-primary {
  color: var(--color-black-subtle);
}

.font-color-secondary {
  color: var(--color-subtle-dark);
}

.font-color-tertiary {
  color: var(--color-subtle-dark);
}
