// Header kendo menu
.k-menu {
  gap: 8px;

  @include mq(desktop) {
    gap: 0;
  }

  .k-menu-expand-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1387 4.75L6.63867 8.25L3.13867 4.75' stroke='%23333F50' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.k-menu-link {
  padding: 8px 12px;
  transition: all .4s;
}

.k-menu-link-text {
  color: var(--color-black-subtle);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  transition: all .4s;
}

.k-menu-link:hover,
.k-menu-link.k-active {
  .k-menu-link-text {
    color: var(--kendo-color-primary);
  }

  .k-menu-expand-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1387 4.75L6.63867 8.25L3.13867 4.75' stroke='%23ED7D31' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.k-menu-popup {
  background: var(--color-white);
  border-color: var(--color-border-primary);
  border-radius: 12px;
  box-shadow: 0px 0px 28px 0px var(--color-focus-shadow);
  padding-block: 8px;

  .k-menu-group {
    gap: 6px;
  }

  .k-menu-link {
    padding-block: 10px;
    padding-inline-start: 8px;
    border-radius: 6px;
  }

  .k-menu-item {
    padding-inline: 8px;
  }

  .k-menu-expand-arrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M4 10.5L8 6.5L4 2.5' stroke='%23333F50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected,
.k-menu-group .k-item > .k-link:hover,
.k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
  background-color: #FDF0E8;

  .k-menu-link-text {
    color: var(--kendo-color-primary);
  }

  .k-menu-expand-arrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M4 10.5L8 6.5L4 2.5' stroke='%23ED7D31' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.k-menu .k-menu-expand-arrow .k-svg-icon,
.k-menu-popup .k-menu-expand-arrow .k-svg-icon {
  display: none;
  transition: all .4s;
}

.k-menu .k-menu-expand-arrow,
.k-menu-popup .k-menu-expand-arrow {
  min-width: 12px;
  aspect-ratio: 1;
  max-width: 12px;
  background-repeat: no-repeat;
}
