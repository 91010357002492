// course popover
.k-popover {
  &:has(.common-popover) {
    --common-popover-gap: 16px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border-primary);
    border-radius: 16px;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.08);

    &::before {
      content: '';
      position: absolute;
      inset: -22px;
      opacity: 0;
    }

    @include mq(tablet) {
      display: none;
    }

    .popover-desc {
      margin-top: 4px;
    }

    .course-info-details {
      gap: 16px;
      padding-top: 16px;
      border-top: 1px solid var(--color-border-primary);
      margin-top: 16px;
    }

    .course-info-item {
      gap: 2px;
      min-width: 0;
      flex: 1 1 0;
    }

    .k-popover-body {
      padding: var(--common-popover-gap);
    }

    .k-actions {
      padding: 0 var(--common-popover-gap) var(--common-popover-gap) var(--common-popover-gap);
      border: 0;
      margin: 0;
    }
  }
}

.k-window {
  border-radius: 16px;
  box-shadow: none;

  .k-window-content,
  .k-prompt-container {
    padding: 12px;
  }
}
