:root {
  --color-white: #fff;
  --color-black: #000;

  --grey-50: #F7F8F9;
  --grey-100: #DFE1E4;
  --grey-200: #B4B9BF;
  --grey-300: #848B96;
  --grey-400: #5c6573;
  --grey-500: #333f50;

  --color-primary: #ED7D31;
  --color-primary-hover: #E37327;
  --color-link-primary: #1862F9;
  --color-link-primary-hover: #0044DB;
  // --color-black-base: #121212;
  --color-black-subtle: #333F50;
  --color-subtle: #F7F8F9;
  --color-subtle-dark: var(--grey-400);
  // --color-base: #667085;
  --color-base-subtle: #C0C3C9;

  --color-border-primary: var(--grey-100);
  --color-input-border-hover-primary: var(--grey-200);
  --color-border-hover-primary: var(--grey-400);

  --color-focus-shadow: var(--grey-100);

  --color-supporting-1: #F5F5F5;
  --color-supporting-2: #F0F0F0;
}


// override kendo variables
:root {
  --kendo-font-family: var(--evg-font-family-primary);
  --kendo-color-primary: var(--color-primary);
  --kendo-color-primary-hover: var(--color-primary-hover);
}
