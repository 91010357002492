/* You can add global styles to this file, and also import other style files */
@import "assets/scss/main.scss";
@import 'kendo-fixes';
.k-dialog-titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}


.custom-noti {
    position: fixed !important;
    top: 150px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 400px;
    font-size: 20px !important;
}

.video-container iframe {
    max-width: 100% !important;
}

@media (max-width: 767px) {
    .custom-noti {
        width: 300px !important;
    }
}
