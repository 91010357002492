.bg-primary {
  background-color: var(--color-subtle);
}

.bg-secondary {
  background-color: var(--color-black-subtle);
}

// .font-hover {
//   &-primary {
//     transition: color .4s;

//     &:hover {
//       color: var(--primary-dark);
//     }
//   }
// }
