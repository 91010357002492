.form-control {
  --form-control-bg-color: var(--color-white);
  --form-control-font-weight: 400;
  --form-control-font-size: 14px;
  --form-control-placeholder-color: var(--color-subtle-dark);
  --form-control-font-color: var(--color-black-subtle);
  --form-control-line-height: 1.25;
  --form-control-padding: 14px 16px;
  --form-control-border-radius: 8px;
  --form-control-border-color: var(--color-border-primary);
  --form-control-focus-border-color: var(--color-input-border-hover-primary);
  --form-control-focus-box-shadow: 0px 0px 0px 3px var(--color-focus-shadow);
  width: 100%;
  background-color: var(--form-control-bg-color);
  font-weight: var(--form-control-font-weight);
  font-size: var(--form-control-font-size);
  color: var(--form-control-font-color);
  line-height: var(--form-control-line-height);
  padding: var(--form-control-padding);
  border: 1px solid var(--form-control-border-color);
  border-radius: var(--form-control-border-radius);
  outline: none;
  transition: all 0.4s;

  &::placeholder {
    color: var(--form-control-placeholder-color);
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: var(--form-control-focus-border-color);
    box-shadow: var(--form-control-focus-box-shadow);
  }
}

kendo-label,
.k-checkbox-label {
  display: block;
  color: var(--color-black-subtle);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 6px;

  // @include mq(phone) {
  //   font-size: 16px;
  // }
}

// .k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: 14px 16px;
}

.k-input-md,
.k-picker-md {
  color: #667185;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}

// kendo-textbox.k-input-md,
// kendo-textbox.k-picker-md,
// kendo-numerictextbox.k-input-md,
// kendo-numerictextbox.k-picker-md {
//   padding: 0.8rem 1.5rem !important;
// }

// input.k-input, textarea.k-textarea {
//   padding-block: 0.8rem;
//   padding-inline: 1.5rem;
// }

span.required {
  color: red;
  font-weight: 600;
}

.k-picker-solid {
  background-color: var(--color-white);
  border: 1px solid var(--color-border-primary);
  border-radius: 8px;
}

.k-picker .k-input-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M9 1L5 5L1 1' stroke='%23667185' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
}

// .k-input-solid,
.k-picker-solid {
  transition: all .4s;

  &:focus,
  &.k-focus {
    border-color: var(--color-input-border-hover-primary);
    box-shadow: 0px 0px 0px 3px var(--color-focus-shadow);
  }
}

.k-upload-button {
  width: 100%;
}

.social-login-btn {
  .k-button-text {
    display: flex;
    align-items: center;
  }
}

// Toggle button
.toggle-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 25px;
  transition: background-color 0.3s;
}

label {
  font-weight: 600;
}

.form-header {
  background: #333f50;
  color: white;
}

.error-container {
  padding: 1rem;
  border: 1px solid #bd0000;
  border-radius: 4px;
  background: #ffd7d7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.error-icon {
  height: 2rem;
}

.error-title {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
  color: #bd0000;
}

.error-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.error-message {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
}

.error-message:hover {
  text-decoration: underline;
}
