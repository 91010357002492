@import "helpers/variables";
@import "helpers/responsive";

.kendo-tab-wrapper {
    --kendo-tab-gap: 24px;

    &:not(.has-filter) {

        &:has(.tab-view-all-area),
        &:has(.owl-nav) {
            .k-tabstrip-items-wrapper {
                @include mq(phone, min) {
                    padding-right: 100px;
                }
            }
        }

        .tab-view-all-area {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: calc(-1 * var(--kendo-tab-gap) - 22px);

            @include mq(phone) {
                justify-content: flex-start;
                position: static;
                margin-top: 18px;

                .k-button-link {
                    padding: 6px;
                    margin: 0 -6px;
                }

            }
        }
    }

    .k-tabstrip {
        &:has(.k-tabstrip-content:not(:empty)) {
            gap: var(--kendo-tab-gap);
        }
    }

    &.has-filter {

        .tab-view-all-area {
            --tab-view-all-gap: 15px;
            border-left: 1px solid #DFE1E4;
            padding-left: var(--tab-view-all-gap);
            margin-left: var(--tab-view-all-gap);
            position: relative;

            @include mq(phone) {
                --tab-view-all-gap: 0;
                border: 0;
            }
        }

        .tab-view-all-area {
            @include mq(phone) {
                order: 3;
                margin-top: 18px;
            }
        }
    }
}

.k-tabstrip {

    .k-tabstrip-content {
        &:focus {
            outline: none;
        }

        .owl-carousel {
            --owl-nav-top-value: var(--kendo-tab-gap);
        }
    }

    .k-tabstrip-items-wrapper,
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        border-color: transparent;
    }


    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        padding: 0;
        overflow: visible;
    }

    .k-tabstrip-items {
        flex-wrap: nowrap;
        gap: 12px;
        @include allow-scroll;

        @include mq(tablet) {
            @include scroll-gap-x;
        }

        .k-item {
            --tab-active-color: #ED7D31;
            --border-color: #ECE2DF;
            background-color: #FDFBFB;
            color: var(--color-black-subtle);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            border-radius: 38px !important;
            border: 1px solid var(--border-color) !important;
            margin: 0 !important;
            box-shadow: none !important;
            transition: all .4s;

            &:active,
            &.k-active,
            &.k-selected {
                --border-color: var(--tab-active-color);
                border-radius: 46px;
                background: rgba(232, 108, 22, 0.10);
            }

            &:hover {
                color: var(--tab-active-color);
            }
        }

        .k-link {
            padding: 10px 16px;
        }
    }

    &.tab-header-center {
        .k-tabstrip-items-wrapper {
            margin-inline: auto;
            max-width: 100%;
        }

        .k-tabstrip-items {
            width: auto !important;
        }
    }
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    border-bottom-color: var(--tab-active-color) !important;
}

// secondary tab style
.k-tab-secondary {
    --kendo-tab-gap: 32px;

    .k-tabstrip {
        .k-tabstrip-items-wrapper {
            border-color: var(--color-border-primary);
        }

        .k-tabstrip-items {
            gap: 24px;

            .k-item {
                --k-item-active-color: transparent;
                position: relative;
                background-color: transparent !important;
                color: rgba(27, 31, 38, 0.72);
                border-width: 0 !important;
                border-radius: 0 !important;

                &::after {
                    content: '';
                    position: absolute;
                    inset: auto 0 0 0;
                    background-color: var(--k-item-active-color);
                    height: 4px;
                    border-radius: 4px 4px 0px 0px;
                    pointer-events: none;
                    transition: all .4s;
                }

                &:active,
                &.k-active,
                &.k-selected {
                    --k-item-active-color: var(--color-primary);
                    color: var(--color-black-subtle);
                }

                &:hover {
                    color: var(--color-black-subtle);
                }
            }

            .k-link {
                padding: 0 0 11px 0;
            }
        }
    }
}
