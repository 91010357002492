.k-panelbar {
    border-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.k-panelbar .k-panelbar-header .k-link {
    background-color: var(--color-white) !important;
    color: var(--color-black-subtle) !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
    box-shadow: none !important;
    padding-block: 10px;
    padding-inline: 0;
}

.k-panelbar > .k-panelbar-header > .k-link,
.k-panelbar .panelbar-header > .k-link {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.k-panelbar .panelbar-header > .k-link {
    padding-inline-end: 0 !important;
}
