.btn {
  background-color: var(--color-primary);
  display: flex;
  gap: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  color: var(--color-black-subtle);
  border: 1px solid var(--color-border-primary);
  cursor: pointer;
}

.add-btn {
  background-color: transparent;
  border-width: 0;
}
