.single-input-form {
  --single-input-form-bg-color: var(--grey-50);
  --single-input-form-focus-bg-color: var(--color-white);
  --single-input-form-input-font-size: 16px;
  --single-input-form-input-font-color: var(--color-black-subtle);
  --single-input-form-input-placeholder-color: var(--color-subtle-dark);
  --single-input-form-input-line-height: 1.4;
  --single-input-form-border-color: var(--color-border-primary);
  --single-input-form-focus-border-color: var(--color-input-border-hover-primary);
  --single-input-form-border-radius: 10px;
  --single-input-form-input-padding: 4px 12px 4px 24px;
  --single-input-form-input-box-shadow-color: var(--color-focus-shadow);
  position: relative;
  background-color: var(--single-input-form-bg-color);
  padding: 0;
  border: 1px solid var(--single-input-form-border-color);
  border-radius: var(--single-input-form-border-radius);
  transition: all 0.4s;

  &:focus-within {
    background-color: var(--single-input-form-focus-bg-color);
    border-color: var(--single-input-form-focus-border-color);
    box-shadow: 0px 0px 0px 3px var(--single-input-form-input-box-shadow-color);
  }

  .form-control {
    --form-control-bg-color: transparent;
    --form-control-font-size: var(--single-input-form-input-font-size);
    --form-control-font-color: var(--single-input-form-input-font-color);
    --form-control-placeholder-color: var(--single-input-form-input-placeholder-color);
    --form-control-line-height: var(--single-input-form-input-line-height);
    --form-control-padding: var(--single-input-form-input-padding);
    --form-control-focus-border-color: transparent;
    --form-control-focus-box-shadow: none;
    flex: 1 1 0;
    border: 0;
  }

  .form-action-btn {
    margin: 6px;
  }
}
