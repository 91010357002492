.k-input-md,
.k-picker-md {
  .k-input-inner {
    padding: 14px 16px;
  }
}

.k-input-solid {
  border-color: var(--grey-100);
  border-radius: 8px;
  color: #333F50;
  transition: all .4s;
}

.k-input-inner::placeholder {
  color: var(--grey-400);
}
