body {
  --layout-container-width: 1300px;
  --layout-container-gap: 20px;
}

.container {
  max-width: calc(var(--layout-container-width) + (var(--layout-container-gap) * 2));
  margin: 0 auto;
  padding: 0 var(--layout-container-gap);
}

.common-section-bd-top {
  --common-section-bd-color: var(--color-border-primary);
  --common-section-bd-gap: 64px;
  position: relative;

  @include mq(phone) {
    --common-section-bd-gap: 40px;
  }

  &::before {
    content: '';
    display: block;
    max-width: clamp(100px, var(--layout-container-width), calc(100% - (var(--layout-container-gap) * 2)));
    height: 1px;
    background-color: var(--common-section-bd-color);
    margin: 0 auto var(--common-section-bd-gap) auto;
  }
}
