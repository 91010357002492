.owl-carousel {
  --owl-hover-color-nav-color: var(--color-white);
  --owl-color-nav-border: #B4B9BF;
  --owl-nav-size: 40px;
  --owl-color-nav-color: var(--owl-color-nav-border);
  --owl-hover-color-nav-border: var(--color-primary);
  --owl-nav-top-value: 0px;

  .owl-stage-outer {
    @include mq(tablet) {
      @include scroll-gap-x;
    }
  }

  .owl-stage {
    display: flex;
    flex-wrap: wrap;

    @include mq(tablet) {
      padding-inline: 0 !important;
    }
  }

  .owl-item {
    float: none;
  }

  .owl-nav {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 8px;
    margin-top: calc(-1 * var(--owl-nav-top-value) - var(--owl-nav-size));

    .owl-prev,
    .owl-next {
      min-width: var(--owl-nav-size);
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--owl-color-nav-color);
      border: 1px solid var(--owl-color-nav-border);
      border-radius: 50%;
      transition: all .4s;

      &:hover {
        background-color: var(--owl-hover-color-nav-border);
        color: var(--owl-hover-color-nav-color);
        border-color: var(--owl-hover-color-nav-border);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
