.skeleton-animation {
  background: linear-gradient(90deg,
      #e0e0e0 25%,
      #f4f4f4 50%,
      #e0e0e0 75%);
  background-size: 200% 100%;
  animation: skeletonAnimation 1.5s infinite;
  border-radius: 5px;
}

@keyframes skeletonAnimation {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
