@mixin hide-scroll {
  & {
    scrollbar-width: none;
  }
}

@mixin allow-scroll {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  > * {
    touch-action: pan-x;
    scroll-snap-align: start;
  }
}

@mixin scroll-gap-x {
  padding-inline: var(--layout-container-gap);
  margin-inline: calc(var(--layout-container-gap) * -1);
  scroll-padding: var(--layout-container-gap);

  @include hide-scroll;
}

// Grid Layout
@mixin grid-layout($columns: var(--grid-columns, 2), $gap: var(--grid-gap)) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
}
