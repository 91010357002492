@import "../input/_variables.scss";
@import "../tooltip/_variables.scss";
@import "../typography/_variables.scss";
@import "../window/_variables.scss";


// Forms

/// The padding of the inline Form.
/// @group form
$kendo-form-spacer: calc( #{$kendo-padding-md-x} * 2 ) !default;

/// The font size of the Form.
/// @group form
$kendo-form-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Form.
/// @group form
$kendo-form-line-height: var( --kendo-line-height, normal ) !default;
/// The line height of the Form in em units.
/// @group form
$kendo-form-line-height-em: calc( #{$kendo-form-line-height} * 1em ) !default;
/// The line height of the small Form.
/// @group form
$kendo-form-sm-line-height: var( --kendo-line-height-sm, normal ) !default;
/// The line height of the large Form.
/// @group form
$kendo-form-lg-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The margin of the Form fieldset.
/// @group form
$kendo-form-fieldset-margin: 2em 0 0 !default;
/// The padding of the Form fieldset.
/// @group form
$kendo-form-fieldset-padding: k-spacing(0) !default;

/// The margin of the Form legend.
/// @group form
$kendo-form-legend-margin: 0 0 1em !default;
/// The padding of the Form legend.
/// @group form
$kendo-form-legend-padding: k-spacing(0) !default;
/// The border width of the Form legend.
/// @group form
$kendo-form-legend-border-width: 0 0 2px !default;
/// The border style of the Form legend.
/// @group form
$kendo-form-legend-border-style: solid !default;
/// The border color of the Form legend.
/// @group form
$kendo-form-legend-border-color: $kendo-component-border !default;
/// The width of the Form legend.
/// @group form
$kendo-form-legend-width: 100% !default;
/// The font size of the Form legend.
/// @group form
$kendo-form-legend-font-size: var( --kendo-font-size, inherit ) !default;
/// The text capitalization of the Form legend.
/// @group form
$kendo-form-legend-text-transform: uppercase !default;

/// The bottom margin of the Form label.
/// @group form
$kendo-form-label-margin-bottom: k-spacing(0) !default;

/// The horizontal margin of the Form buttons.
/// @group form
$kendo-form-button-margin-x: k-spacing(2) !default;

/// The font size of the Form hint.
/// @group form
$kendo-form-hint-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font style of the Form hint.
/// @group form
$kendo-form-hint-font-style: italic !default;
/// The top margin of the Form hint.
/// @group form
$kendo-form-hint-margin-top: k-spacing(1) !default;

/// The row spacing of the small Form.
/// @group form
$kendo-form-sm-rows-spacing: k-spacing(2.5) !default;
/// The row spacing of the medium Form.
/// @group form
$kendo-form-md-rows-spacing: k-spacing(3.5) !default;
/// The row spacing of the large Form.
/// @group form
$kendo-form-lg-rows-spacing: k-spacing(4.5) !default;

/// The margin of the Form separator.
/// @group form
$kendo-form-separator-margin: $kendo-form-md-rows-spacing 0 0 !default;
/// The border color of the Form separator.
/// @group form
$kendo-form-separator-border-color: $kendo-form-legend-border-color !default;

/// The top padding of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-padding-top: calc( #{k-spacing(2.5)} / 2 ) !default;
/// The horizontal margin of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-margin-x: k-spacing(2.5) !default;
/// The width of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-width: 25% !default;
/// The horizontal alignment of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-align: flex-end !default;

/// The maximum width of the field wrap in the horizontal Form.
/// @group form
$kendo-horizontal-form-field-wrap-max-width: calc( ( 100% - #{$kendo-horizontal-form-label-width} ) - #{$kendo-horizontal-form-label-margin-x} ) !default;

/// The width of the inline Form element.
/// @group form
$kendo-inline-form-element-width: 25% !default;

/// The invalid text color of the Form.
/// @group form
$kendo-forms-invalid-color: $kendo-color-error !default;


/// The horizontal margin of the optional label in the Form.
/// @group form
$kendo-label-optional-margin-x: k-spacing(1.5) !default;
/// The font size of the optional label in the Form.
/// @group form
$kendo-label-optional-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font style of the optional label in the Form.
/// @group form
$kendo-label-optional-font-style: italic !default;


/// The margin of the Form fieldset.
/// @group form
$kendo-fieldset-margin: k-spacing(7.5) !default;
/// The font size of the Form fieldset.
/// @group form
$kendo-fieldset-font-size: $kendo-h4-font-size !default;
/// The background color of the Form fieldset.
/// @group form
$kendo-fieldset-bg: null !default;
/// The text color of the Form fieldset.
/// @group form
$kendo-fieldset-text: null !default;
/// The border color of the Form fieldset.
/// @group form
$kendo-fieldset-border: null !default;

/// The background color of the Form legend.
/// @group form
$kendo-fieldset-legend-bg: null !default;
/// The text color of the Form legend.
/// @group form
$kendo-fieldset-legend-text: if($kendo-enable-color-system, k-color( on-app-surface ), k-try-shade( $kendo-body-text, 2 )) !default;
/// The border color of the Form legend.
/// @group form
$kendo-fieldset-legend-border: null !default;

/// The sizes map for the Form.
/// @group form
$kendo-form-sizes: (
    sm: (
        form-rows-spacing: $kendo-form-sm-rows-spacing
    ),
    md: (
        form-rows-spacing: $kendo-form-md-rows-spacing
    ),
    lg: (
        form-rows-spacing: $kendo-form-lg-rows-spacing
    )
) !default;
