@import "helpers/variables";

.k-checkbox {
  border-color: var(--color-border-primary);

  &-md {
    @include mq(phone) {
      --checkbox-size: 20px;
      width: var(--checkbox-size);
      height: var(--checkbox-size);
    }
  }
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-size: 12px;
}
