* {
  scrollbar-width: thin;
  scrollbar-color: #E2E2E2 transparent;
}

.app-logo {
  min-width: 125px;
  max-width: 125px;
  cursor: pointer;

  img {
    width: 100%;
    max-width: 100%;
  }
}

.main-empty-state-card {
  --main-empty-state-card-min-height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  min-height: var(--main-empty-state-card-min-height);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-100);
  border-radius: 16px;

  .empty-state-card-text {
    color: var(--color-black-subtle);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
  }

  .empty-state-card-icon {
    display: flex;
  }
}

kendo-splitter-bar kendo-svgicon {
  width: 3rem !important;
  height: 3rem !important;
}
